import { generateClient } from "@aws-amplify/api";
import { createPaymentRequest } from "../graphql/mutations";
import { PaymentRequest } from "../models/API";
import { getPaymentRequest } from "../graphql/queries";

// Store payment request
export async function getPaymentRequestService(id: string) {
  try {
    const client = generateClient();

    const listing: any = await client.graphql<PaymentRequest>({
      query: getPaymentRequest,
      variables: { id },
    });
    const single = listing.data.getPaymentRequest;

    return single;
  } catch (error) {
    console.error(error);
  }
}

export async function createPaymentRequestService(payment: any) {
  try {
    // console.log({ payment });
    const client = generateClient();

    const response: any = await client.graphql({
      query: createPaymentRequest,
      variables: { input: payment },
    });

    const paymentRequest = response.data.createPaymentRequest;

    return paymentRequest ? paymentRequest.id : "";
  } catch (error) {
    console.error(error);
  }
}
