export enum MoneyTypes {
  PERCENTAGE = "percentage",
  FIXED = "fixed",
}
export enum LamdaFnLinks {
  TableManagement = "https://rffd2z6wvvkpgxqjgxiggpb3ha0xsbbo.lambda-url.us-east-2.on.aws/",
  SimphonyOperations = "https://lgw7mzmhwttgpzl66k3wfywnty0nicsy.lambda-url.us-east-2.on.aws/",
  PaymentOperations = "https://6diviisxg3z74jdnnpbyocfkkq0kpcum.lambda-url.us-east-2.on.aws/",
  LoyaltyOperations = "https://hmdipoittruzvcwacnkhihtb5i0wxkrk.lambda-url.us-east-2.on.aws/",
}

export enum TableStatus {
  OCCUPIED = "occupied",
  AVAILABLE = "available",
  RESERVED = "reserved",
  UNAVAILABLE = "unavailable",
}

export enum PaymentChannels {
  APPLE = "applePay",
  SAMSUNG = "samsungPay",
  CARD = "cardPay",
}
