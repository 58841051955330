import {
  Backdrop,
  Box,
  Button,
  Grid,
  Slide,
  Typography,
  createTheme,
} from "@mui/material";

import lightThemeOptions from "../../theme/lightThemeOptions";
import { useState } from "react";
import { StoreState } from "../../store/ducks";
import { useSelector } from "react-redux";
import { calcWithServiceFees } from "../../pages/Invoice/InvoiceTable";
import { getCurrentTip } from "../tipsComponent/TipsComponent";
import { FaPencilAlt } from "react-icons/fa";
import CustomTipModal from "../tipsComponent/CustomInputModal";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { getEquivalentSosocial } from "../../helpers/utils";
const lightTheme = createTheme(lightThemeOptions);

type Props = {
  open: boolean;
  setCash: (cash: number) => void;
  onClose: () => void;
};
function RedemptionModal({ open, setCash, onClose }: Props) {
  const user = useSelector((state: StoreState) => state.app.user);
  const wallet_cash = Number(user?.wallet.amount);
  const conceptOptions = useSelector(
    (state: StoreState) => state.app.conceptOptions
  );
  const { currentDue, order, tip } = useSelector(
    (state: StoreState) => state.order
  );
  const maxBill =
    currentDue +
      calcWithServiceFees({
        total: currentDue,
        serviceFee: conceptOptions?.serviceFee!,
      }).serviceFee >
    wallet_cash
      ? wallet_cash
      : currentDue +
        calcWithServiceFees({
          total: currentDue,
          serviceFee: conceptOptions?.serviceFee!,
        }).serviceFee;
  const [customInput, setCustomInput] = useState<boolean>(false);

  const handleClose = () => {
    setCustomInput(false);
    onClose();
  };
  const handleSetCash = (cash: number) => {
    setCustomInput(false);
    setCash(cash);
  };

  return (
    <>
      <Backdrop
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 0,
          alignItems: "flex-end",
          backgroundColor: "unset",
        }}
        open={open}
        onClick={(e) => handleClose()}
      >
        <CustomTipModal
          open={customInput}
          handleSubmit={handleSetCash}
          onClose={() => setCustomInput(false)}
          max={
            getEquivalentSosocial(maxBill, user?.soSocialConversion) >
            user?.wallet.amount!
              ? user?.wallet.amount
              : getEquivalentSosocial(maxBill, user?.soSocialConversion)
          }
        />
        <Slide in={open} direction="up">
          <Box
            sx={{ ...classes.containerBox, paddingBottom: "300px" }}
            onClick={(e) => e.stopPropagation()}
          >
            <Grid container sx={classes.containerGrid}>
              <Grid
                item
                sx={{
                  gap: "10px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                xs={12}
              >
                <Box sx={{ width: "100%" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "start",
                    }}
                  >
                    <Typography
                      fontSize={15}
                      fontWeight={"bold"}
                      sx={{ width: "100%", color: "#606060" }}
                    >
                      Redeem Social Cash
                    </Typography>
                    <IoIosCloseCircleOutline
                      color="red"
                      onClick={handleClose}
                      size={"24px"}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    border: "1px solid #C4C4C4",
                    borderRadius: "32px",
                    padding: "14px",
                  }}
                >
                  <Box sx={{ mb: "16px", mt: "12px" }}>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography
                        component={"span"}
                        fontWeight={"bold"}
                        fontSize={14}
                        sx={{
                          color: "#959595",
                          "@media (max-width: 390px)": {
                            fontSize: "12px", // Example adjustment for small screens
                          },
                        }}
                      >
                        {user?.first_name} {user?.last_name}
                      </Typography>{" "}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "start",
                      }}
                    >
                      <Typography
                        fontSize={12}
                        component={"span"}
                        fontWeight={"bold"}
                        sx={{
                          color: "#959595",
                          "@media (max-width: 390px)": {
                            fontSize: "10px",
                          },
                        }}
                      >
                        Wallet Balance : {wallet_cash.toFixed(2)}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-end",
                        }}
                      >
                        <Typography
                          fontSize={10}
                          fontWeight={"medium"}
                          sx={{
                            color: "#959595",
                            "@media (max-width: 390px)": {
                              fontSize: "9px", // Example adjustment for small screens
                            },
                          }}
                        >
                          Maximum usable on this bill:{" "}
                          {getEquivalentSosocial(
                            maxBill,
                            user?.soSocialConversion
                          ).toFixed(2)}
                        </Typography>{" "}
                        <Typography
                          fontSize={10}
                          fontWeight={"medium"}
                          sx={{
                            color: "#959595",
                            "@media (max-width: 390px)": {
                              fontSize: "9px", // Example adjustment for small screens
                            },
                          }}
                        >
                          (Equivalent To: EGP&nbsp;{maxBill.toFixed(2)})
                        </Typography>{" "}
                      </Box>
                    </Box>
                    <Typography
                      fontSize={14}
                      fontWeight={"bold"}
                      sx={{
                        color: "#959595",
                        "@media (max-width: 390px)": {
                          fontSize: "12px", // Example adjustment for small screens
                        },
                      }}
                    >
                      Bill Amount: EGP&nbsp;
                      {(
                        currentDue +
                        calcWithServiceFees({
                          total: currentDue,
                          serviceFee: conceptOptions?.serviceFee!,
                        }).serviceFee +
                        getCurrentTip({
                          tip,
                          total: order?.Due!,
                          currentDue,
                        })
                      ).toFixed(2)}{" "}
                    </Typography>{" "}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: "10px",
                    }}
                  >
                    <Button
                      onClick={() => {
                        if (
                          user?.wallet.amount! >
                          getEquivalentSosocial(
                            maxBill,
                            user?.soSocialConversion
                          )
                        ) {
                          setCash(
                            getEquivalentSosocial(
                              maxBill,
                              user?.soSocialConversion
                            )
                          );
                        } else {
                          setCash(user?.wallet.amount!);
                        }
                      }}
                      variant="contained"
                      sx={{
                        ...classes.useAmountButton,
                        "@media (max-width: 390px)": {
                          fontSize: "11px", // Example adjustment for small screens
                          padding: "12px 8px", // Adjust padding for smaller screens
                        },
                      }}
                      disabled={Boolean(Number(user?.wallet.amount) < 1)}
                    >
                      Use SoSocial{" "}
                      {getEquivalentSosocial(
                        maxBill,
                        user?.soSocialConversion
                      ) > user?.wallet.amount!
                        ? user?.wallet.amount.toFixed(2)
                        : getEquivalentSosocial(
                            maxBill,
                            user?.soSocialConversion
                          ).toFixed(2)}
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        ...classes.customAmountButton,
                        "@media (max-width: 390px)": {
                          fontSize: "11px", // Example adjustment for small screens
                          padding: "12px 8px", // Adjust padding for smaller screens
                        },
                      }}
                      onClick={() => setCustomInput(true)}
                      disabled={Boolean(Number(user?.wallet.amount) < 1)}
                    >
                      Custom Amount
                      <Box
                        sx={{
                          width: "20px",
                          height: "20px",
                          marginLeft: "5px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                          border:
                            "2px solid " + lightTheme.palette.secondary.main,
                          borderRadius: "50%",
                        }}
                      >
                        <FaPencilAlt
                          color={lightTheme.palette.secondary.main}
                          size={"12px"}
                        />
                      </Box>
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Slide>
      </Backdrop>
    </>
  );
}
const classes = {
  containerBox: {
    boxSizing: "border-box",
    position: "relative",
    width: "100%",
    background: "#FFFFFF",
    padding: "35px",
    borderRadius: "50px 50px 0px 0px",
    boxShadow: "0px 0px 1px 0px",
  },
  containerGrid: {
    height: "100%",
    gap: "1rem",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
    justifyItems: "center",
  },
  useAmountButton: {
    backgroundColor: "black",
    textTransform: "unset",
    fontSize: "12px",
    fontWeight: "medium",
    borderRadius: "32px",
    py: "13px",
    width: "48%",
    "&:hover": {
      backgroundColor: "black",
    },
  },
  customAmountButton: {
    backgroundColor: "transparent",
    textTransform: "unset",
    fontSize: "12px",
    fontWeight: "medium",
    borderRadius: "32px",
    color: lightTheme.palette.secondary.main,
    py: "10px",
    width: "48%",
    boxShadow: "none",
    border: "1px solid #C4C4C4",
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  },
};
export default RedemptionModal;
