/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../models/API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createAccount = /* GraphQL */ `mutation CreateAccount(
  $input: CreateAccountInput!
  $condition: ModelAccountConditionInput
) {
  createAccount(input: $input, condition: $condition) {
    id
    logo {
      id
      mediaID
      fileUrl
      filename
      filetype
      fileSize
      alternativeText
      caption
      description
      createdAt
      createdByID
      createdByName
      updatedAt
      __typename
    }
    mainBg {
      id
      mediaID
      fileUrl
      filename
      filetype
      fileSize
      alternativeText
      caption
      description
      createdAt
      createdByID
      createdByName
      updatedAt
      __typename
    }
    domain
    siteTitle
    tagline
    description
    siteAddress
    defaultLanguage
    languages
    features
    status
    socialLinks
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    accountLogoId
    accountMainBgId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAccountMutationVariables,
  APITypes.CreateAccountMutation
>;
export const updateAccount = /* GraphQL */ `mutation UpdateAccount(
  $input: UpdateAccountInput!
  $condition: ModelAccountConditionInput
) {
  updateAccount(input: $input, condition: $condition) {
    id
    logo {
      id
      mediaID
      fileUrl
      filename
      filetype
      fileSize
      alternativeText
      caption
      description
      createdAt
      createdByID
      createdByName
      updatedAt
      __typename
    }
    mainBg {
      id
      mediaID
      fileUrl
      filename
      filetype
      fileSize
      alternativeText
      caption
      description
      createdAt
      createdByID
      createdByName
      updatedAt
      __typename
    }
    domain
    siteTitle
    tagline
    description
    siteAddress
    defaultLanguage
    languages
    features
    status
    socialLinks
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    accountLogoId
    accountMainBgId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAccountMutationVariables,
  APITypes.UpdateAccountMutation
>;
export const deleteAccount = /* GraphQL */ `mutation DeleteAccount(
  $input: DeleteAccountInput!
  $condition: ModelAccountConditionInput
) {
  deleteAccount(input: $input, condition: $condition) {
    id
    logo {
      id
      mediaID
      fileUrl
      filename
      filetype
      fileSize
      alternativeText
      caption
      description
      createdAt
      createdByID
      createdByName
      updatedAt
      __typename
    }
    mainBg {
      id
      mediaID
      fileUrl
      filename
      filetype
      fileSize
      alternativeText
      caption
      description
      createdAt
      createdByID
      createdByName
      updatedAt
      __typename
    }
    domain
    siteTitle
    tagline
    description
    siteAddress
    defaultLanguage
    languages
    features
    status
    socialLinks
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    accountLogoId
    accountMainBgId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAccountMutationVariables,
  APITypes.DeleteAccountMutation
>;
export const createConcept = /* GraphQL */ `mutation CreateConcept(
  $input: CreateConceptInput!
  $condition: ModelConceptConditionInput
) {
  createConcept(input: $input, condition: $condition) {
    id
    accountID
    name
    description
    logo
    mainBg
    type
    location
    precedence
    longitude
    latitude
    openingHours
    closingHours
    posID
    simphonyIP
    rvcIP
    simphonyEmployeeID
    tenderID
    visaTenderID
    applePayTenderID
    samsungPayTenderID
    tipsNum
    CheckOrderType
    emailTemplateName
    discountObjectNum
    secondaryTender
    EnableLoyalty
    emailName
    emailLocation
    emailPhone
    tips {
      id
      amount
      type
      default
      __typename
    }
    serviceFee {
      amount
      type
      __typename
    }
    soSocialEarnRatio
    soSocialRedeemRatio
    paymentMerchants {
      channel
      merchant_id
      access_code
      enc_key
      url
      env
      __typename
    }
    refundMerchants {
      channel
      merchant_id
      access_code
      enc_key
      url
      env
      __typename
    }
    applePayMerchantIdentifier
    isMPGS
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateConceptMutationVariables,
  APITypes.CreateConceptMutation
>;
export const updateConcept = /* GraphQL */ `mutation UpdateConcept(
  $input: UpdateConceptInput!
  $condition: ModelConceptConditionInput
) {
  updateConcept(input: $input, condition: $condition) {
    id
    accountID
    name
    description
    logo
    mainBg
    type
    location
    precedence
    longitude
    latitude
    openingHours
    closingHours
    posID
    simphonyIP
    rvcIP
    simphonyEmployeeID
    tenderID
    visaTenderID
    applePayTenderID
    samsungPayTenderID
    tipsNum
    CheckOrderType
    emailTemplateName
    discountObjectNum
    secondaryTender
    EnableLoyalty
    emailName
    emailLocation
    emailPhone
    tips {
      id
      amount
      type
      default
      __typename
    }
    serviceFee {
      amount
      type
      __typename
    }
    soSocialEarnRatio
    soSocialRedeemRatio
    paymentMerchants {
      channel
      merchant_id
      access_code
      enc_key
      url
      env
      __typename
    }
    refundMerchants {
      channel
      merchant_id
      access_code
      enc_key
      url
      env
      __typename
    }
    applePayMerchantIdentifier
    isMPGS
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateConceptMutationVariables,
  APITypes.UpdateConceptMutation
>;
export const deleteConcept = /* GraphQL */ `mutation DeleteConcept(
  $input: DeleteConceptInput!
  $condition: ModelConceptConditionInput
) {
  deleteConcept(input: $input, condition: $condition) {
    id
    accountID
    name
    description
    logo
    mainBg
    type
    location
    precedence
    longitude
    latitude
    openingHours
    closingHours
    posID
    simphonyIP
    rvcIP
    simphonyEmployeeID
    tenderID
    visaTenderID
    applePayTenderID
    samsungPayTenderID
    tipsNum
    CheckOrderType
    emailTemplateName
    discountObjectNum
    secondaryTender
    EnableLoyalty
    emailName
    emailLocation
    emailPhone
    tips {
      id
      amount
      type
      default
      __typename
    }
    serviceFee {
      amount
      type
      __typename
    }
    soSocialEarnRatio
    soSocialRedeemRatio
    paymentMerchants {
      channel
      merchant_id
      access_code
      enc_key
      url
      env
      __typename
    }
    refundMerchants {
      channel
      merchant_id
      access_code
      enc_key
      url
      env
      __typename
    }
    applePayMerchantIdentifier
    isMPGS
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteConceptMutationVariables,
  APITypes.DeleteConceptMutation
>;
export const createUserConcepts = /* GraphQL */ `mutation CreateUserConcepts(
  $input: CreateUserConceptsInput!
  $condition: ModelUserConceptsConditionInput
) {
  createUserConcepts(input: $input, condition: $condition) {
    id
    defaultConcept
    concepts
    conceptsRoles
    deleted
    userID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserConceptsMutationVariables,
  APITypes.CreateUserConceptsMutation
>;
export const updateUserConcepts = /* GraphQL */ `mutation UpdateUserConcepts(
  $input: UpdateUserConceptsInput!
  $condition: ModelUserConceptsConditionInput
) {
  updateUserConcepts(input: $input, condition: $condition) {
    id
    defaultConcept
    concepts
    conceptsRoles
    deleted
    userID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserConceptsMutationVariables,
  APITypes.UpdateUserConceptsMutation
>;
export const deleteUserConcepts = /* GraphQL */ `mutation DeleteUserConcepts(
  $input: DeleteUserConceptsInput!
  $condition: ModelUserConceptsConditionInput
) {
  deleteUserConcepts(input: $input, condition: $condition) {
    id
    defaultConcept
    concepts
    conceptsRoles
    deleted
    userID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserConceptsMutationVariables,
  APITypes.DeleteUserConceptsMutation
>;
export const createAttachment = /* GraphQL */ `mutation CreateAttachment(
  $input: CreateAttachmentInput!
  $condition: ModelAttachmentConditionInput
) {
  createAttachment(input: $input, condition: $condition) {
    id
    mediaID
    fileUrl
    filename
    filetype
    fileSize
    alternativeText
    caption
    description
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAttachmentMutationVariables,
  APITypes.CreateAttachmentMutation
>;
export const updateAttachment = /* GraphQL */ `mutation UpdateAttachment(
  $input: UpdateAttachmentInput!
  $condition: ModelAttachmentConditionInput
) {
  updateAttachment(input: $input, condition: $condition) {
    id
    mediaID
    fileUrl
    filename
    filetype
    fileSize
    alternativeText
    caption
    description
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAttachmentMutationVariables,
  APITypes.UpdateAttachmentMutation
>;
export const deleteAttachment = /* GraphQL */ `mutation DeleteAttachment(
  $input: DeleteAttachmentInput!
  $condition: ModelAttachmentConditionInput
) {
  deleteAttachment(input: $input, condition: $condition) {
    id
    mediaID
    fileUrl
    filename
    filetype
    fileSize
    alternativeText
    caption
    description
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAttachmentMutationVariables,
  APITypes.DeleteAttachmentMutation
>;
export const createFeature = /* GraphQL */ `mutation CreateFeature(
  $input: CreateFeatureInput!
  $condition: ModelFeatureConditionInput
) {
  createFeature(input: $input, condition: $condition) {
    id
    name
    icon
    slug
    precedence
    parent
    private
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFeatureMutationVariables,
  APITypes.CreateFeatureMutation
>;
export const updateFeature = /* GraphQL */ `mutation UpdateFeature(
  $input: UpdateFeatureInput!
  $condition: ModelFeatureConditionInput
) {
  updateFeature(input: $input, condition: $condition) {
    id
    name
    icon
    slug
    precedence
    parent
    private
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFeatureMutationVariables,
  APITypes.UpdateFeatureMutation
>;
export const deleteFeature = /* GraphQL */ `mutation DeleteFeature(
  $input: DeleteFeatureInput!
  $condition: ModelFeatureConditionInput
) {
  deleteFeature(input: $input, condition: $condition) {
    id
    name
    icon
    slug
    precedence
    parent
    private
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFeatureMutationVariables,
  APITypes.DeleteFeatureMutation
>;
export const createAdminRole = /* GraphQL */ `mutation CreateAdminRole(
  $input: CreateAdminRoleInput!
  $condition: ModelAdminRoleConditionInput
) {
  createAdminRole(input: $input, condition: $condition) {
    id
    name
    description
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAdminRoleMutationVariables,
  APITypes.CreateAdminRoleMutation
>;
export const updateAdminRole = /* GraphQL */ `mutation UpdateAdminRole(
  $input: UpdateAdminRoleInput!
  $condition: ModelAdminRoleConditionInput
) {
  updateAdminRole(input: $input, condition: $condition) {
    id
    name
    description
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAdminRoleMutationVariables,
  APITypes.UpdateAdminRoleMutation
>;
export const deleteAdminRole = /* GraphQL */ `mutation DeleteAdminRole(
  $input: DeleteAdminRoleInput!
  $condition: ModelAdminRoleConditionInput
) {
  deleteAdminRole(input: $input, condition: $condition) {
    id
    name
    description
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAdminRoleMutationVariables,
  APITypes.DeleteAdminRoleMutation
>;
export const createAdminGroup = /* GraphQL */ `mutation CreateAdminGroup(
  $input: CreateAdminGroupInput!
  $condition: ModelAdminGroupConditionInput
) {
  createAdminGroup(input: $input, condition: $condition) {
    id
    name
    description
    roles
    users
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAdminGroupMutationVariables,
  APITypes.CreateAdminGroupMutation
>;
export const updateAdminGroup = /* GraphQL */ `mutation UpdateAdminGroup(
  $input: UpdateAdminGroupInput!
  $condition: ModelAdminGroupConditionInput
) {
  updateAdminGroup(input: $input, condition: $condition) {
    id
    name
    description
    roles
    users
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAdminGroupMutationVariables,
  APITypes.UpdateAdminGroupMutation
>;
export const deleteAdminGroup = /* GraphQL */ `mutation DeleteAdminGroup(
  $input: DeleteAdminGroupInput!
  $condition: ModelAdminGroupConditionInput
) {
  deleteAdminGroup(input: $input, condition: $condition) {
    id
    name
    description
    roles
    users
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAdminGroupMutationVariables,
  APITypes.DeleteAdminGroupMutation
>;
export const createMedia = /* GraphQL */ `mutation CreateMedia(
  $input: CreateMediaInput!
  $condition: ModelMediaConditionInput
) {
  createMedia(input: $input, condition: $condition) {
    id
    accountID
    fileUrl
    filename
    filetype
    fileSize
    alternativeText
    caption
    description
    attachments
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMediaMutationVariables,
  APITypes.CreateMediaMutation
>;
export const updateMedia = /* GraphQL */ `mutation UpdateMedia(
  $input: UpdateMediaInput!
  $condition: ModelMediaConditionInput
) {
  updateMedia(input: $input, condition: $condition) {
    id
    accountID
    fileUrl
    filename
    filetype
    fileSize
    alternativeText
    caption
    description
    attachments
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMediaMutationVariables,
  APITypes.UpdateMediaMutation
>;
export const deleteMedia = /* GraphQL */ `mutation DeleteMedia(
  $input: DeleteMediaInput!
  $condition: ModelMediaConditionInput
) {
  deleteMedia(input: $input, condition: $condition) {
    id
    accountID
    fileUrl
    filename
    filetype
    fileSize
    alternativeText
    caption
    description
    attachments
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMediaMutationVariables,
  APITypes.DeleteMediaMutation
>;
export const createTable = /* GraphQL */ `mutation CreateTable(
  $input: CreateTableInput!
  $condition: ModelTableConditionInput
) {
  createTable(input: $input, condition: $condition) {
    id
    tableCode
    accountID
    conceptID
    simphonyID
    name
    status
    updatedAt
    deleted
    createdAt
    createdByID
    createdByName
    currentOrder {
      id
      accountID
      conceptID
      tableSimphonyID
      paymentID
      deleted
      createdAt
      updatedAt
      paid
      serviceFee
      tips
      paidAmount
      refundedAmount
      remainingAmount
      CheckNumber
      CheckSeqNumber
      SubTotal
      Tax
      totalOrderAmount
      Due
      Payment
      AdditionalPayment
      CheckId
      CheckOpenTime
      CheckCloseTime
      TableOpenTime
      MenuItems {
        DetailType
        DetailLink
        obj
        Total
        RevCtrID
        mTaxCollectedTotal
        Name
        SalesCount
        paidQTY
        totalAmount
        price
        __typename
      }
      DiscountItems {
        DetailType
        DetailLink
        obj
        Total
        RevCtrID
        mTaxCollectedTotal
        Name
        SalesCount
        paidQTY
        pcnt
        totalAmount
        price
        __typename
      }
      usedCash
      ratings {
        id
        rating
        __typename
      }
      __typename
    }
    lastClosedAt
    lastPaymentStatus
    lastUsedCash
    tableCurrentOrderId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTableMutationVariables,
  APITypes.CreateTableMutation
>;
export const updateTable = /* GraphQL */ `mutation UpdateTable(
  $input: UpdateTableInput!
  $condition: ModelTableConditionInput
) {
  updateTable(input: $input, condition: $condition) {
    id
    tableCode
    accountID
    conceptID
    simphonyID
    name
    status
    updatedAt
    deleted
    createdAt
    createdByID
    createdByName
    currentOrder {
      id
      accountID
      conceptID
      tableSimphonyID
      paymentID
      deleted
      createdAt
      updatedAt
      paid
      serviceFee
      tips
      paidAmount
      refundedAmount
      remainingAmount
      CheckNumber
      CheckSeqNumber
      SubTotal
      Tax
      totalOrderAmount
      Due
      Payment
      AdditionalPayment
      CheckId
      CheckOpenTime
      CheckCloseTime
      TableOpenTime
      MenuItems {
        DetailType
        DetailLink
        obj
        Total
        RevCtrID
        mTaxCollectedTotal
        Name
        SalesCount
        paidQTY
        totalAmount
        price
        __typename
      }
      DiscountItems {
        DetailType
        DetailLink
        obj
        Total
        RevCtrID
        mTaxCollectedTotal
        Name
        SalesCount
        paidQTY
        pcnt
        totalAmount
        price
        __typename
      }
      usedCash
      ratings {
        id
        rating
        __typename
      }
      __typename
    }
    lastClosedAt
    lastPaymentStatus
    lastUsedCash
    tableCurrentOrderId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTableMutationVariables,
  APITypes.UpdateTableMutation
>;
export const deleteTable = /* GraphQL */ `mutation DeleteTable(
  $input: DeleteTableInput!
  $condition: ModelTableConditionInput
) {
  deleteTable(input: $input, condition: $condition) {
    id
    tableCode
    accountID
    conceptID
    simphonyID
    name
    status
    updatedAt
    deleted
    createdAt
    createdByID
    createdByName
    currentOrder {
      id
      accountID
      conceptID
      tableSimphonyID
      paymentID
      deleted
      createdAt
      updatedAt
      paid
      serviceFee
      tips
      paidAmount
      refundedAmount
      remainingAmount
      CheckNumber
      CheckSeqNumber
      SubTotal
      Tax
      totalOrderAmount
      Due
      Payment
      AdditionalPayment
      CheckId
      CheckOpenTime
      CheckCloseTime
      TableOpenTime
      MenuItems {
        DetailType
        DetailLink
        obj
        Total
        RevCtrID
        mTaxCollectedTotal
        Name
        SalesCount
        paidQTY
        totalAmount
        price
        __typename
      }
      DiscountItems {
        DetailType
        DetailLink
        obj
        Total
        RevCtrID
        mTaxCollectedTotal
        Name
        SalesCount
        paidQTY
        pcnt
        totalAmount
        price
        __typename
      }
      usedCash
      ratings {
        id
        rating
        __typename
      }
      __typename
    }
    lastClosedAt
    lastPaymentStatus
    lastUsedCash
    tableCurrentOrderId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTableMutationVariables,
  APITypes.DeleteTableMutation
>;
export const createOrder = /* GraphQL */ `mutation CreateOrder(
  $input: CreateOrderInput!
  $condition: ModelOrderConditionInput
) {
  createOrder(input: $input, condition: $condition) {
    id
    accountID
    conceptID
    tableSimphonyID
    paymentID
    deleted
    createdAt
    updatedAt
    paid
    serviceFee
    tips
    paidAmount
    refundedAmount
    remainingAmount
    CheckNumber
    CheckSeqNumber
    SubTotal
    Tax
    totalOrderAmount
    Due
    Payment
    AdditionalPayment
    CheckId
    CheckOpenTime
    CheckCloseTime
    TableOpenTime
    MenuItems {
      DetailType
      DetailLink
      obj
      Total
      RevCtrID
      mTaxCollectedTotal
      Name
      SalesCount
      paidQTY
      totalAmount
      price
      __typename
    }
    DiscountItems {
      DetailType
      DetailLink
      obj
      Total
      RevCtrID
      mTaxCollectedTotal
      Name
      SalesCount
      paidQTY
      pcnt
      totalAmount
      price
      __typename
    }
    usedCash
    ratings {
      id
      rating
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOrderMutationVariables,
  APITypes.CreateOrderMutation
>;
export const updateOrder = /* GraphQL */ `mutation UpdateOrder(
  $input: UpdateOrderInput!
  $condition: ModelOrderConditionInput
) {
  updateOrder(input: $input, condition: $condition) {
    id
    accountID
    conceptID
    tableSimphonyID
    paymentID
    deleted
    createdAt
    updatedAt
    paid
    serviceFee
    tips
    paidAmount
    refundedAmount
    remainingAmount
    CheckNumber
    CheckSeqNumber
    SubTotal
    Tax
    totalOrderAmount
    Due
    Payment
    AdditionalPayment
    CheckId
    CheckOpenTime
    CheckCloseTime
    TableOpenTime
    MenuItems {
      DetailType
      DetailLink
      obj
      Total
      RevCtrID
      mTaxCollectedTotal
      Name
      SalesCount
      paidQTY
      totalAmount
      price
      __typename
    }
    DiscountItems {
      DetailType
      DetailLink
      obj
      Total
      RevCtrID
      mTaxCollectedTotal
      Name
      SalesCount
      paidQTY
      pcnt
      totalAmount
      price
      __typename
    }
    usedCash
    ratings {
      id
      rating
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateOrderMutationVariables,
  APITypes.UpdateOrderMutation
>;
export const deleteOrder = /* GraphQL */ `mutation DeleteOrder(
  $input: DeleteOrderInput!
  $condition: ModelOrderConditionInput
) {
  deleteOrder(input: $input, condition: $condition) {
    id
    accountID
    conceptID
    tableSimphonyID
    paymentID
    deleted
    createdAt
    updatedAt
    paid
    serviceFee
    tips
    paidAmount
    refundedAmount
    remainingAmount
    CheckNumber
    CheckSeqNumber
    SubTotal
    Tax
    totalOrderAmount
    Due
    Payment
    AdditionalPayment
    CheckId
    CheckOpenTime
    CheckCloseTime
    TableOpenTime
    MenuItems {
      DetailType
      DetailLink
      obj
      Total
      RevCtrID
      mTaxCollectedTotal
      Name
      SalesCount
      paidQTY
      totalAmount
      price
      __typename
    }
    DiscountItems {
      DetailType
      DetailLink
      obj
      Total
      RevCtrID
      mTaxCollectedTotal
      Name
      SalesCount
      paidQTY
      pcnt
      totalAmount
      price
      __typename
    }
    usedCash
    ratings {
      id
      rating
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteOrderMutationVariables,
  APITypes.DeleteOrderMutation
>;
export const createPaymentRequest = /* GraphQL */ `mutation CreatePaymentRequest(
  $input: CreatePaymentRequestInput!
  $condition: ModelPaymentRequestConditionInput
) {
  createPaymentRequest(input: $input, condition: $condition) {
    id
    usedCash
    user
    conceptId
    tableSimphonyId
    CheckNum
    CheckSeq
    payment {
      type
      amount
      billAmount
      tips
      serviceCharge
      items {
        DetailLink
        qty
        __typename
      }
      userEmail
      paymentMethod
      __typename
    }
    percentageArray
    soSocialConversion
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePaymentRequestMutationVariables,
  APITypes.CreatePaymentRequestMutation
>;
export const updatePaymentRequest = /* GraphQL */ `mutation UpdatePaymentRequest(
  $input: UpdatePaymentRequestInput!
  $condition: ModelPaymentRequestConditionInput
) {
  updatePaymentRequest(input: $input, condition: $condition) {
    id
    usedCash
    user
    conceptId
    tableSimphonyId
    CheckNum
    CheckSeq
    payment {
      type
      amount
      billAmount
      tips
      serviceCharge
      items {
        DetailLink
        qty
        __typename
      }
      userEmail
      paymentMethod
      __typename
    }
    percentageArray
    soSocialConversion
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePaymentRequestMutationVariables,
  APITypes.UpdatePaymentRequestMutation
>;
export const deletePaymentRequest = /* GraphQL */ `mutation DeletePaymentRequest(
  $input: DeletePaymentRequestInput!
  $condition: ModelPaymentRequestConditionInput
) {
  deletePaymentRequest(input: $input, condition: $condition) {
    id
    usedCash
    user
    conceptId
    tableSimphonyId
    CheckNum
    CheckSeq
    payment {
      type
      amount
      billAmount
      tips
      serviceCharge
      items {
        DetailLink
        qty
        __typename
      }
      userEmail
      paymentMethod
      __typename
    }
    percentageArray
    soSocialConversion
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePaymentRequestMutationVariables,
  APITypes.DeletePaymentRequestMutation
>;
export const createPayment = /* GraphQL */ `mutation CreatePayment(
  $input: CreatePaymentInput!
  $condition: ModelPaymentConditionInput
) {
  createPayment(input: $input, condition: $condition) {
    id
    accountID
    conceptID
    orderID
    tableID
    tableName
    channel
    tableOpenTime
    usedCash
    billAmount
    tipAmount
    serviceFees
    order_id
    tracking_id
    bank_ref_no
    order_status
    failure_message
    payment_mode
    card_name
    status_code
    status_message
    currency
    Amount
    billing_name
    billing_address
    billing_city
    billing_state
    billing_zip
    billing_country
    billing_tel
    billing_email
    merchant_param1
    merchant_param2
    merchant_param3
    merchant_param4
    merchant_param5
    merchant_param6
    mer_amount
    eci_value
    card_holder_name
    bank_receipt_no
    bank_qsi_no
    updatedSimphony
    updatedDBOrder
    errorMessage
    updatedSosocialWallet
    refunded
    refundedAmount
    percentageArray
    soSocialConversion
    earned
    deleted
    CheckNum
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePaymentMutationVariables,
  APITypes.CreatePaymentMutation
>;
export const updatePayment = /* GraphQL */ `mutation UpdatePayment(
  $input: UpdatePaymentInput!
  $condition: ModelPaymentConditionInput
) {
  updatePayment(input: $input, condition: $condition) {
    id
    accountID
    conceptID
    orderID
    tableID
    tableName
    channel
    tableOpenTime
    usedCash
    billAmount
    tipAmount
    serviceFees
    order_id
    tracking_id
    bank_ref_no
    order_status
    failure_message
    payment_mode
    card_name
    status_code
    status_message
    currency
    Amount
    billing_name
    billing_address
    billing_city
    billing_state
    billing_zip
    billing_country
    billing_tel
    billing_email
    merchant_param1
    merchant_param2
    merchant_param3
    merchant_param4
    merchant_param5
    merchant_param6
    mer_amount
    eci_value
    card_holder_name
    bank_receipt_no
    bank_qsi_no
    updatedSimphony
    updatedDBOrder
    errorMessage
    updatedSosocialWallet
    refunded
    refundedAmount
    percentageArray
    soSocialConversion
    earned
    deleted
    CheckNum
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePaymentMutationVariables,
  APITypes.UpdatePaymentMutation
>;
export const deletePayment = /* GraphQL */ `mutation DeletePayment(
  $input: DeletePaymentInput!
  $condition: ModelPaymentConditionInput
) {
  deletePayment(input: $input, condition: $condition) {
    id
    accountID
    conceptID
    orderID
    tableID
    tableName
    channel
    tableOpenTime
    usedCash
    billAmount
    tipAmount
    serviceFees
    order_id
    tracking_id
    bank_ref_no
    order_status
    failure_message
    payment_mode
    card_name
    status_code
    status_message
    currency
    Amount
    billing_name
    billing_address
    billing_city
    billing_state
    billing_zip
    billing_country
    billing_tel
    billing_email
    merchant_param1
    merchant_param2
    merchant_param3
    merchant_param4
    merchant_param5
    merchant_param6
    mer_amount
    eci_value
    card_holder_name
    bank_receipt_no
    bank_qsi_no
    updatedSimphony
    updatedDBOrder
    errorMessage
    updatedSosocialWallet
    refunded
    refundedAmount
    percentageArray
    soSocialConversion
    earned
    deleted
    CheckNum
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePaymentMutationVariables,
  APITypes.DeletePaymentMutation
>;
export const createReview = /* GraphQL */ `mutation CreateReview(
  $input: CreateReviewInput!
  $condition: ModelReviewConditionInput
) {
  createReview(input: $input, condition: $condition) {
    id
    accountID
    conceptID
    orderID
    paymentReceipt
    rating
    review
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateReviewMutationVariables,
  APITypes.CreateReviewMutation
>;
export const updateReview = /* GraphQL */ `mutation UpdateReview(
  $input: UpdateReviewInput!
  $condition: ModelReviewConditionInput
) {
  updateReview(input: $input, condition: $condition) {
    id
    accountID
    conceptID
    orderID
    paymentReceipt
    rating
    review
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateReviewMutationVariables,
  APITypes.UpdateReviewMutation
>;
export const deleteReview = /* GraphQL */ `mutation DeleteReview(
  $input: DeleteReviewInput!
  $condition: ModelReviewConditionInput
) {
  deleteReview(input: $input, condition: $condition) {
    id
    accountID
    conceptID
    orderID
    paymentReceipt
    rating
    review
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteReviewMutationVariables,
  APITypes.DeleteReviewMutation
>;
export const createUserIP = /* GraphQL */ `mutation CreateUserIP(
  $input: CreateUserIPInput!
  $condition: ModelUserIPConditionInput
) {
  createUserIP(input: $input, condition: $condition) {
    id
    ip
    date
    times
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserIPMutationVariables,
  APITypes.CreateUserIPMutation
>;
export const updateUserIP = /* GraphQL */ `mutation UpdateUserIP(
  $input: UpdateUserIPInput!
  $condition: ModelUserIPConditionInput
) {
  updateUserIP(input: $input, condition: $condition) {
    id
    ip
    date
    times
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserIPMutationVariables,
  APITypes.UpdateUserIPMutation
>;
export const deleteUserIP = /* GraphQL */ `mutation DeleteUserIP(
  $input: DeleteUserIPInput!
  $condition: ModelUserIPConditionInput
) {
  deleteUserIP(input: $input, condition: $condition) {
    id
    ip
    date
    times
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserIPMutationVariables,
  APITypes.DeleteUserIPMutation
>;
export const createMainCategory = /* GraphQL */ `mutation CreateMainCategory(
  $input: CreateMainCategoryInput!
  $condition: ModelMainCategoryConditionInput
) {
  createMainCategory(input: $input, condition: $condition) {
    id
    conceptID
    name
    precedence
    menuPDF
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMainCategoryMutationVariables,
  APITypes.CreateMainCategoryMutation
>;
export const updateMainCategory = /* GraphQL */ `mutation UpdateMainCategory(
  $input: UpdateMainCategoryInput!
  $condition: ModelMainCategoryConditionInput
) {
  updateMainCategory(input: $input, condition: $condition) {
    id
    conceptID
    name
    precedence
    menuPDF
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMainCategoryMutationVariables,
  APITypes.UpdateMainCategoryMutation
>;
export const deleteMainCategory = /* GraphQL */ `mutation DeleteMainCategory(
  $input: DeleteMainCategoryInput!
  $condition: ModelMainCategoryConditionInput
) {
  deleteMainCategory(input: $input, condition: $condition) {
    id
    conceptID
    name
    precedence
    menuPDF
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMainCategoryMutationVariables,
  APITypes.DeleteMainCategoryMutation
>;
