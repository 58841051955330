import {
  Box,
  Grid,
  Rating,
  SxProps,
  Typography,
  createTheme,
  Button,
  CircularProgress,
  TextField,
  Divider,
  IconContainerProps,
  styled,
} from "@mui/material";
import { useEffect, useState } from "react";
import { FaRegCheckCircle } from "react-icons/fa";
import { FaRegCircleXmark } from "react-icons/fa6";
import lightThemeOptions from "../../theme/lightThemeOptions";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import NotFound from "../../components/NotFound";
import { useTranslation } from "react-i18next";
import AuthModal from "../../components/AuthModal/AuthModal";
import { useSelector } from "react-redux";
import { StoreState } from "../../store/ducks";
import { getPaymentRequestService } from "../../services/payment";
import { FaHeart } from "react-icons/fa";
import RatingsModal from "../../components/Modals/RatingsModal";
import { sendEmail } from "../../services/sendEmail";
import RateAndReviewOnGoogle from "../../components/Modals/RateAndReviewOnGoogle";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAltOutlined";
import { TranslationsEnum } from "../../constants/TranslationsEnum";
import { GrGift } from "react-icons/gr";
import { FaHome } from "react-icons/fa";
import { AiOutlineExclamationCircle } from "react-icons/ai";
const lightTheme = createTheme(lightThemeOptions);

const PaymentResult = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const { id } = useParams();
  const info = queryParams.get("info");
  const source = queryParams.get("source");
  console.log(source);
  const paymentRequestID = queryParams.get("payment-request-id");
  const orderID = queryParams.get("order-id");
  const dbOrderID = queryParams.get("dbOrderId") || "";
  const dbTableId = queryParams.get("tableid") || "";
  console.log({ orderID });
  const StyledRating = styled(Rating)(({ theme }) => ({
    "& .MuiRating-iconEmpty .MuiSvgIcon-root": {
      color: "grey",
    },
  }));
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [review, setReview] = useState("");
  const [rating, setRating] = useState<number | null>(null);
  const [data, setData] = useState<any>(null);
  const [paymentInfo, setPaymentInfo] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [loyaltyLoading, setLoyaltyLoading] = useState(false);
  const [authModal, setAuthModal] = useState(false);
  const [openRatingModal, setOpenRatingModal] = useState(false);
  const [loadingRating, setLoadingRating] = useState(false);
  const [rated, setRated] = useState<number | null>(null);
  const [openGoogleRating, setOpenGoogleRating] = useState(false);
  const [loadingSendingMail, setLoadingSendingMail] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [homeId, setHomeId] = useState("");
  const user = useSelector((state: StoreState) => state.app.user);
  const conceptOptions = useSelector(
    (state: StoreState) => state.app.conceptOptions
  );
  const [earned, setEarned] = useState(false);
  const onRatingChange = (_: any, newValue: number | null) => {
    setRating(newValue);
    setOpenRatingModal(true);
  };
  const customIcons: {
    [index: string]: {
      icon: React.ReactElement;
      label: string;
    };
  } = {
    1: {
      icon: <SentimentDissatisfiedIcon fontSize="large" color="error" />,
      label: "Dissatisfied",
    },
    2: {
      icon: <SentimentSatisfiedIcon fontSize="large" color="warning" />,
      label: "Neutral",
    },
    3: {
      icon: <SentimentSatisfiedAltIcon fontSize="large" color="success" />,
      label: "Satisfied",
    },
  };
  const paymentFailed =
    data && data.order_status && data.order_status.toLowerCase() !== "success";
  const updatedSimphony = data && data.updatedSimphony;
  const updatedWallet = data && data.updatedSosocialWallet;
  function IconContainer(props: IconContainerProps) {
    const { value, ...other } = props;
    return <span {...other}>{customIcons[value].icon}</span>;
  }
  // useEffect(() => {
  //   const handleEarning = async () => {
  //     try {
  //       if (user) {
  //         setLoyaltyLoading(true);
  //         const response = await handlePosEarn(
  //           // conceptOptions?.pos_id,
  //           "401",
  //           user.id,
  //           paymentInfo.CheckNum,
  //           paymentInfo.payment.billAmount,
  //           data.paymentID
  //         );
  //         if (response.result.toLowerCase() === "success") {
  //           toast.success("You earned SoSocial Cash successfully");
  //           setEarned(true);
  //         } else {
  //           toast.error("There was an error in earning.");
  //         }
  //       }
  //     } catch (error: any) {
  //       if (error.response.data.error === "already earned") {
  //         toast.error("You have already earned SoSocial Cash");
  //         setEarned(true);
  //       } else {
  //         toast.error("Error Earning SoSocial Cash");
  //         console.log(error);
  //       }
  //     } finally {
  //       setLoyaltyLoading(false);
  //     }
  //   };
  //   handleEarning();
  // }, [user]);
  const handleHomeNavigation = () => {
    if (source === "app" && !paymentFailed) {
      window.location.href =
        "https://api.sosocial.com/api/v1/transaction/payment-success";
      return;
    }
    navigate(`/${homeId}`);
  };
  useEffect(() => {
    async function getPaymentDetails() {
      if (info) {
        try {
          setLoading(true);

          const parsed = JSON.parse(info);
          const id = parsed.merchant_param5;
          // Get payment request ID inside merchant_param5
          const parsedData = await getPaymentRequestService(id);

          // Get payment details inside merchant_param5
          // const decodedString = atob(string);
          // const parsedData = JSON.parse(decodedString);

          setPaymentInfo(parsedData);
          console.log({ parsedData });

          setData(parsed);
          setHomeId(parsed.merchant_param4);
        } catch (error) {
          console.log("Error parsing payment data" + error);
          toast.error("Error getting payment status");
        } finally {
          setLoading(false);
        }
      } else if (paymentRequestID) {
        const paymentRequest = await getPaymentRequestService(paymentRequestID);
        console.log({ paymentRequest });
        setPaymentInfo(paymentRequest);
        setData(paymentRequest);
        console.log({ dbTableId });
        setHomeId(dbTableId);
        setLoading(false);
      }
    }

    getPaymentDetails();
  }, [info]);

  const handleOpenAuth = () => {
    if (!earned) setAuthModal(true);
  };
  const handleSuccessfulRating = (fullRated: boolean) => {
    setRated(5);
    setOpenRatingModal(false);
    if (fullRated) {
      setOpenGoogleRating(true);
    }
  };
  const sendReceipt = async () => {
    try {
      setLoadingSendingMail(true);

      const response = await sendEmail({
        conceptId: paymentInfo.conceptId,
        tableSimphonyId: paymentInfo.tableSimphonyId,
        CheckNum: paymentInfo.CheckNum,
        CheckSeq: paymentInfo.CheckSeq,
        payment: { ...paymentInfo.payment, userEmail: email },
        usedCash: paymentInfo.usedCash ? paymentInfo.usedCash : 0,
      });

      toast.success("Successfully sent the email.");
    } catch (error) {
      console.log("Error sending email" + error);
      toast.error("there has been an error sending the email.");
    } finally {
      setLoadingSendingMail(false);
    }
  };
  const handleEmailChange = (e: any) => {
    const email = e.target.value;

    // Regex pattern for validating email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmail(email);

    if (emailRegex.test(email) || email === "") {
      setEmailError("");
    } else {
      setEmailError("Invalid email address");
    }
  };
  /* Invalid payment status screen */
  if (!loading && !data) {
    return <NotFound type={"payment"} />;
  }

  /* Loading screen */
  if (loading) {
    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{ height: "100vh" }}
      >
        <CircularProgress color="primary" />
      </Grid>
    );
  }

  /* Payment details screen */
  console.log({ data });
  console.log(source);
  return (
    <Box sx={classes.containerBox}>
      <AuthModal
        open={authModal}
        onClose={() => setAuthModal(false)}
        earn={true}
        setUsedCash={(e) => console.log(e)}
      />
      <RatingsModal
        open={openRatingModal}
        onClose={() => setOpenRatingModal(false)}
        rating={rating}
        bank_receipt_no={paymentInfo.id}
        merchant_param2={dbOrderID ? dbOrderID : data.merchant_param2}
        handleSuccessfulRating={handleSuccessfulRating}
      />
      <RateAndReviewOnGoogle
        open={openGoogleRating}
        onClose={() => setOpenGoogleRating(false)}
      />
      <Grid container sx={classes.containerGrid}>
        {/* Failed Payment */}
        {paymentFailed ? (
          <>
            <Box sx={classes.iconContainer}>
              <FaRegCircleXmark fontSize="80px" color={"#d32f2f"} />
            </Box>

            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              <Typography
                fontSize={"15px"}
                fontWeight={"bold"}
                color={"secondary"}
              >
                Payment Failed
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={"medium"}
                color={"primary"}
                textAlign={"center"}
              >
                Please try paying your bill again
              </Typography>
            </Grid>

            {/* <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                gap: "5px",
                mt: "25px",
              }}
            >
              <Button
                startIcon={loading && <CircularProgress size={20} />}
                sx={classes.finalPayment}
                onClick={handleHomeNavigation}
                disabled={loading}
              >
                Try Again
              </Button>
            </Grid> */}
          </>
        ) : (
          <>
            {!dbTableId &&
            (!updatedSimphony || (!updatedWallet && data.usedCash > 0)) ? (
              <>
                <Box sx={classes.iconContainer}>
                  <AiOutlineExclamationCircle
                    fontSize="80px"
                    stroke="2px"
                    color={"#F4C542"}
                  />
                </Box>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <Typography
                    fontSize={"15px"}
                    fontWeight={"bold"}
                    color={"secondary"}
                  >
                    Payment Successful
                  </Typography>
                  <Typography
                    fontSize={14}
                    fontWeight={"medium"}
                    color={"primary"}
                    textAlign={"center"}
                  >
                    Thank you, your payment has been successful. But There was
                    an issue updating info. &nbsp;Please contact an admin.
                  </Typography>
                </Grid>
              </>
            ) : (
              <>
                <Box sx={classes.iconContainer}>
                  <FaRegCheckCircle
                    fontSize="80px"
                    stroke="2px"
                    color={"#37b629"}
                  />
                </Box>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <Typography
                    fontSize={"15px"}
                    fontWeight={"bold"}
                    color={"secondary"}
                  >
                    Payment Successful
                  </Typography>
                  <Typography
                    fontSize={14}
                    fontWeight={"medium"}
                    color={"primary"}
                    textAlign={"center"}
                  >
                    Thank you, your payment has been successful. Check the below
                    order details.
                  </Typography>
                </Grid>
              </>
            )}

            {/* COMMENTED FOR NOW - SoSocial */}
            {/* SoSocial Loyalty Section  */}
            {/* {!Boolean(paymentInfo.usedCash) && (
              <Grid
                item
                xs={12}
                sx={{
                  background: earned
                    ? "#a4a4a4"
                    : "linear-gradient(135deg, rgba(207,58,113,1) 0%, rgba(252,216,40,1) 50%, rgba(159,199,50,1) 100%)",
                  borderRadius: "15px",
                  display: "flex",
                  justifyContent: "center",
                  gap: "5px",
                  padding: "15px",
                  alignItems: "center",
                }}
                onClick={handleOpenAuth}
              >
                {loyaltyLoading ? (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <CircularProgress color="primary" />
                    </Box>
                  </>
                ) : earned ? (
                  <>
                    <Box>
                      <GoGift fontSize={"26px"} color="white" />
                    </Box>
                    <Typography
                      fontSize={"15px"}
                      fontWeight={"medium"}
                      color={"white"}
                    >
                      You have earned&nbsp;
                      <Typography
                        fontSize={"15px"}
                        fontWeight={"bold"}
                        color={"white"}
                        component={"span"}
                      >
                        {(Number(data.mer_amount) * 0.2).toFixed(0)}&nbsp;
                      </Typography>
                      SoSocial Cash.
                    </Typography>
                  </>
                ) : (
                  <>
                    <Box>
                      <GoGift fontSize={"26px"} color={"black"} />
                    </Box>
                    <Typography
                      fontSize={"14px"}
                      fontWeight={"bold"}
                      color={"black"}
                    >
                      Collect your SoSocial cash back
                    </Typography>
                  </>
                )}
              </Grid>
            )} */}

            {/* Order Details Section */}
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              <Typography
                fontSize={"15px"}
                fontWeight={"bold"}
                color={"secondary"}
                textAlign={"left"}
              >
                Order Details
              </Typography>
              <Box
                sx={{
                  backgroundColor: "#f6f6f6",
                  borderRadius: "15px",
                  padding: "25px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <Box sx={{ display: "flex", gap: "5px" }}>
                  <Typography fontSize={14} fontWeight={"bold"}>
                    Order ID:
                  </Typography>
                  <Typography
                    fontSize={14}
                    fontWeight={"medium"}
                    color="#909090"
                  >
                    {data.order_id || orderID}
                  </Typography>
                </Box>
                {/* <Box sx={{ display: "flex", gap: "5px" }}>
                <Typography fontSize={14} fontWeight={"bold"}>
                  Order Date:
                </Typography>
                <Typography
                  fontSize={14}
                  fontWeight={"medium"}
                  color="#909090"
                >
                  {order &&
                    order.createdAt &&
                    new Date(data.).toDateString()}
                </Typography>
              </Box> */}
                <Box sx={{ display: "flex", gap: "5px" }}>
                  <Typography fontSize={14} fontWeight={"bold"}>
                    Paid Amount:
                  </Typography>
                  <Typography
                    fontSize={14}
                    fontWeight={"medium"}
                    color="#909090"
                  >
                    {data.mer_amount
                      ? Number(data.mer_amount).toFixed(2)
                      : data.payment?.amount?.toFixed(2)}{" "}
                    {/* {currentDue.toFixed(2)} EGP */}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", gap: "5px" }}>
                  <Typography fontSize={14} fontWeight={"bold"}>
                    Cheque Status:
                  </Typography>
                  <Typography
                    fontSize={14}
                    fontWeight={"medium"}
                    color="#909090"
                    sx={{ textTransform: "capitalize" }}
                  >
                    {paymentInfo.payment.type}
                  </Typography>
                </Box>
              </Box>

              {/* Rating box */}
              <Box
                sx={{
                  mt: 2,
                  backgroundColor: "#f6f6f6",
                  borderRadius: "15px",
                  border: "1px solid #e3e3e3",
                  padding: "25px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                {rated ? (
                  <>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      Your review has been submitted
                    </Typography>
                    {rated === 5 && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        {/* <Typography
                          sx={{
                            fontSize: "12px",
                          }}
                        >
                          Would you like to
                        </Typography>
                        <Button
                          variant="contained"
                          sx={{
                            backgroundColor: "black",
                            fontSize: "12px",
                            textTransform: "none",
                            borderRadius: "5px",
                            px: "10px",
                            ml: "3px",
                            "&:hover": {
                              backgroundColor: "black",
                            },
                          }}
                        >
                          <Typography sx={{ fontSize: "9px" }}>
                            Share on&nbsp;
                            <Typography
                              sx={{ fontSize: "9px" }}
                              fontWeight={"bold"}
                              component={"span"}
                            >
                              Google Maps
                            </Typography>
                          </Typography>
                        </Button> */}
                      </Box>
                    )}
                  </>
                ) : (
                  <>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      Your Feedback Matters
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                      }}
                    >
                      We would love to hear about it
                    </Typography>
                  </>
                )}
                {rated ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        width: "32px",
                        height: "32px",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "black",
                        mb: "5px",
                      }}
                    >
                      <FaHeart fontSize={"20px"} color="white" />
                    </Box>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "medium",
                      }}
                    >
                      Thanks for sharing feedback
                    </Typography>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <StyledRating
                      value={rating}
                      onChange={onRatingChange}
                      disabled={loadingRating || Boolean(rated)}
                      name="highlight-selected-only"
                      defaultValue={1}
                      IconContainerComponent={IconContainer}
                      getLabelText={(value: number) => ""}
                      highlightSelectedOnly
                      max={3}
                      size="large"
                    />
                    {/* <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        height: "16px",
                        mt: "5px",
                        gap: "30px",
                      }}
                    >
                      <Typography fontSize={12}>Not Satisfied</Typography>
                      <Typography fontSize={12}>Satisfied</Typography>
                    </Box> */}
                  </Box>
                )}

                {/* <Divider orientation="horizontal" sx={{ width: "110%" }} />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    py: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    Follow us on social media
                  </Typography>
                  <Box sx={{ mt: "5px", display: "flex", gap: "10px" }}>
                    <Box
                      component={"a"}
                      target="_blank"
                      href="https://www.instagram.com/tiki.pacifico/"
                      sx={{
                        border: "1px solid grey",
                        backgroundColor: "white",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "20px",
                        height: "20px",
                        py: "5px",
                        px: "5px",
                        borderRadius: "50%",
                        cursor: "pointer",
                        color: "black",
                        "&:hover": {
                          backgroundColor: "secondary",
                        },
                      }}
                      aria-label="add to shopping cart"
                    >
                      <FaInstagram color="inherit" />
                    </Box>
                    <Box
                      component={"a"}
                      target="_blank"
                      href="https://www.facebook.com/PacificoTikiDiningLounge"
                      sx={{
                        border: "1px solid grey",
                        backgroundColor: "white",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "20px",
                        height: "20px",
                        py: "5px",
                        px: "5px",
                        borderRadius: "50%",
                        cursor: "pointer",
                        color: "black",
                        "&:hover": {
                          backgroundColor: "secondary",
                        },
                      }}
                      aria-label="add to shopping cart"
                    >
                      <FaFacebook color="inherit" />
                    </Box>
                    <Box
                      sx={{
                        border: "1px solid grey",
                        backgroundColor: "white",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "20px",
                        height: "20px",
                        py: "5px",
                        px: "5px",
                        borderRadius: "50%",
                        cursor: "pointer",
                        color: "black",
                        "&:hover": {
                          backgroundColor: "secondary",
                        },
                      }}
                      aria-label="add to shopping cart"
                    >
                      <FaTripadvisor color="inherit" />
                    </Box>
                  </Box>
                </Box> */}
                <Divider orientation="horizontal" sx={{ width: "110%" }} />
                <Box
                  sx={{
                    py: "10px",
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    Get receipt via email
                  </Typography>
                  <Box
                    sx={{
                      mt: "5px",
                      display: "flex",
                      gap: "5px",
                      flexDirection: "column",
                    }}
                  >
                    <TextField
                      variant="outlined"
                      fullWidth
                      autoComplete="email"
                      inputProps={{ autoComplete: "email" }}
                      disabled={
                        Boolean(paymentInfo?.payment?.userEmail) ||
                        loadingSendingMail
                      }
                      value={email}
                      onChange={handleEmailChange}
                      error={Boolean(emailError)}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& .MuiInputBase-input": {
                            padding: "10px",
                            px: "10px",
                            color: lightTheme.palette.secondary.main,
                            fontSize: "14px",
                            fontWeight: "bold",
                          },
                          "&.Mui-disabled": {
                            "& .MuiInputBase-input": {
                              color: lightTheme.palette.text.disabled, // Style for the input text when disabled
                            },
                            backgroundColor:
                              lightTheme.palette.action.disabledBackground, // Background color when disabled
                            borderColor: lightTheme.palette.action.disabled, // Border color when disabled
                          },
                          "& fieldset": {},
                          "&.Mui-focused fieldset": {},
                          "&:hover fieldset": {},
                        },
                      }}
                    />
                    <Box sx={{ height: "10px" }}>
                      {emailError && (
                        <Typography fontSize={12} color={"error"}>
                          Invalid Email
                        </Typography>
                      )}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      mt: "10px",
                      display: "flex",
                      justifyContent: "center",
                      gap: "10px",
                    }}
                  >
                    <Button
                      startIcon={
                        loadingSendingMail && <CircularProgress size={20} />
                      }
                      sx={classes.finalPayment}
                      onClick={sendReceipt}
                      disabled={
                        Boolean(paymentInfo?.payment?.userEmail) ||
                        loadingSendingMail
                      }
                    >
                      {t("Submit")}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </>
        )}
        <Button
          onClick={handleHomeNavigation}
          variant={"contained"}
          sx={classes.payWithSoSocial}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            {" "}
            <FaHome
              style={{
                fontSize: "24px",
                color: "#000",
              }}
            />
            <Typography fontWeight={"bold"}>Go Home</Typography>
          </Box>
        </Button>
      </Grid>
    </Box>
  );
};
const classes = {
  iconContainer: {
    backgroundColor: "white",
    width: "130px",
    height: "130px",
    borderRadius: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    top: "-70px",
    filter: "drop-shadow(0px 3px 6px #00000029)",
  },
  containerBox: {
    boxSizing: "border-box",
    position: "relative",
    width: "100%",
    background: "#FFFFFF",
    padding: {
      xs: "35px",
      sm: "1rem",
      md: "1.5rem",
    },
    mt: "50px",
    borderRadius: "50px 50px 0px 0px",
    // maxWidth: "500px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  containerGrid: {
    height: "100%",
    gap: "1rem",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  finalPayment: {
    backgroundColor: "black",
    textTransform: "unset",
    fontSize: "15px",
    fontWeight: "bold",
    borderRadius: "32px",
    color: "white",
    mb: "5px",
    py: "10px",
    "&:hover": {
      backgroundColor: "black",
    },
    "&:disabled": {
      backgroundColor: "gray",
      color: "#3a3a3a",
    },
    minWidth: "250px",
  } as SxProps,
  payWithSoSocial: {
    // backgroundColor: "black",
    background:
      "linear-gradient(135deg, rgba(207,58,113,1) 0%, rgba(252,216,40,1) 50%, rgba(159,199,50,1) 100%)",
    textTransform: "unset",
    fontSize: "15px",
    fontWeight: "bold",
    borderRadius: "32px",
    mb: "5px",
    py: "10px",
    width: "100%",
    color: "black",
    "&:hover": {
      backgroundColor: "black",
    },
    "&:disabled": {
      backgroundColor: "gray",
      color: "#3a3a3a",
    },
  },
};
export default PaymentResult;
