import { IoIosCloseCircleOutline } from "react-icons/io";
import {
  Backdrop,
  Box,
  Button,
  Grid,
  InputAdornment,
  Slide,
  TextField,
  Typography,
  createTheme,
} from "@mui/material";
import lightThemeOptions from "../../theme/lightThemeOptions";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { StoreState } from "../../store/ducks";
import { calcWithServiceFees } from "../../pages/Invoice/InvoiceTable";
import { toast } from "react-toastify";
const lightTheme = createTheme(lightThemeOptions);

type Props = {
  open: boolean;
  onClose: () => void;
  handleSubmit: (value: number) => void;
  handleRemove: () => void;
};
function CustomAmountModal({
  open,
  onClose,
  handleSubmit,
  handleRemove,
}: Props) {
  const handleClose = () => {
    // setValue(confirmedValue);
    onClose();
  };
  const handleRemoveSplit = () => {
    handleRemove();
    setValue(0);
    onClose();
  };
  const handleValueChange = (e: any) => {
    const value = e.target.value;
    if (value.length > 10) {
      return;
    }
    setValue(Number(value));
  };
  const [value, setValue] = useState(0);
  // const [confirmedValue, setConfirmedValue] = useState(0);
  const total = useSelector(
    (state: StoreState) => state.order.total + state.order.tip
  );
  const { order } = useSelector((state: StoreState) => state.order);
  const { serviceFee } = useSelector(
    (state: StoreState) => state.app.conceptOptions!
  );
  return (
    <>
      <Backdrop
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 0,
          alignItems: "flex-end",
        }}
        onClick={(e) => handleClose()}
        open={open}
      >
        <Slide in={open} direction="up">
          <Box sx={classes.containerBox} onClick={(e) => e.stopPropagation()}>
            <Grid container sx={classes.containerGrid}>
              <Grid
                item
                sx={{
                  gap: "10px",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Typography
                    fontSize={15}
                    fontWeight="medium"
                    color={lightTheme.palette.secondary.main}
                  >
                    Pay a Custom Amount
                  </Typography>
                  <IoIosCloseCircleOutline
                    color="red"
                    onClick={handleClose}
                    size={"24px"}
                  />{" "}
                </Box>
              </Grid>
              <Grid
                item
                sx={{
                  gap: "10px",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <TextField
                  placeholder=""
                  onChange={handleValueChange}
                  value={value === 0 ? "" : value}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="start"
                        sx={{ fontSize: "10px" }}
                      >
                        <Typography
                          sx={{ fontSize: "10px", fontWeight: "700" }}
                        >
                          EGP
                        </Typography>
                      </InputAdornment>
                    ),
                    inputProps: { min: 0, max: Number(total) },
                  }}
                  type={"number"}
                  sx={{
                    mt: "5px",
                    "& .MuiOutlinedInput-root": {
                      "& .MuiInputBase-input": {
                        padding: "10px",
                        px: "19px",
                        color: lightTheme.palette.secondary.main,
                        fontSize: "15px",
                        fontWeight: "medium",
                      },
                      "& fieldset": {
                        borderRadius: "32px",
                        borderColor: lightTheme.palette.secondary.main,
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "black",
                      },
                      "&:hover fieldset": {
                        borderColor: "black",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid
                item
                sx={{
                  gap: "10px",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  mt: "auto",
                  borderTop: "1px solid " + lightTheme.palette.secondary.main,
                  px: "5px",
                  pt: "10px",
                }}
              >
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    fontSize={14}
                    fontWeight={"medium"}
                    color={lightTheme.palette.secondary.main}
                  >
                    Left to Pay
                  </Typography>
                  <Typography
                    fontSize={14}
                    fontWeight={"medium"}
                    color={lightTheme.palette.secondary.main}
                  >
                    <Typography
                      component={"span"}
                      fontSize={14}
                      fontWeight={"medium"}
                    >
                      EGP&nbsp;
                    </Typography>
                    {(
                      order?.Due! +
                      calcWithServiceFees({
                        serviceFee: serviceFee,
                        total: order?.Due!,
                      }).serviceFee
                    ).toFixed(2)}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    fontSize={14}
                    fontWeight={"medium"}
                    color={lightTheme.palette.secondary.main}
                  >
                    Your Share
                  </Typography>
                  <Typography
                    fontSize={14}
                    fontWeight={"medium"}
                    color={lightTheme.palette.secondary.main}
                  >
                    <Typography
                      component={"span"}
                      fontSize={14}
                      fontWeight={"medium"}
                    >
                      EGP&nbsp;
                    </Typography>{" "}
                    {calcWithServiceFees({
                      total: value,
                      serviceFee,
                    }).total.toFixed(2)}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Button
                    variant="contained"
                    sx={classes.splitButton}
                    onClick={handleRemoveSplit}
                  >
                    Remove Split
                  </Button>
                  <Button
                    variant="contained"
                    disabled={value === 0}
                    sx={{
                      ...classes.payFull,
                    }}
                    onClick={() => {
                      if (value === 0) return;
                      const dueWithServiceFee =
                        order?.Due! +
                        calcWithServiceFees({ serviceFee, total: order?.Due! })
                          .serviceFee;
                      if (value > dueWithServiceFee) {
                        toast.info("You can't pay more than the due amount");
                        return;
                      }
                      // setConfirmedValue(value);
                      if (Math.abs(value - dueWithServiceFee) < 0.001) {
                        handleSubmit(order?.Due!);
                      } else {
                        handleSubmit(value);
                      }
                    }}
                  >
                    Confirm
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Slide>
      </Backdrop>
    </>
  );
}
const classes = {
  containerBox: {
    boxSizing: "border-box",
    position: "relative",
    width: "100%",
    height: "100%",
    background: "#FFFFFF",
    padding: {
      xs: "35px",
      sm: "1rem",
      md: "1.5rem",
    },
    maxWidth: "500px",
  },
  containerGrid: {
    height: "100%",
    gap: "1rem",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "start",
    flexDirection: "column",
  },
  walletTitle: {
    fontWeight: "bold",
    fontSize: "15px",
    color: lightTheme.palette.secondary.main,
  },
  walletSubtitle: {
    fontWeight: "medium",
    fontSize: "14px",
    color: lightTheme.palette.secondary.main,
  },
  walletText: {
    fontWeight: "bold",
    fontSize: "16px",
    color: lightTheme.palette.secondary.main,
  },
  selectButton: {
    backgroundColor: "#F5F5F5",
    borderRadius: "32px",
    color: lightTheme.palette.secondary.main,
    fontSize: "14px",
    boxShadow: "none",
    fontWeight: "medium",
  },
  choiceBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  choiceText: {
    color: lightTheme.palette.secondary.main,
    fontSize: "14px",
    fontWeight: "medium",
  },
  choicesContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  splitButton: {
    width: "45%",
    backgroundColor: "#FAFAFA",
    borderRadius: "32px",
    boxShadow: "none",
    py: "10px",
    color: "red",
    fontWeight: "medium",
    fontSize: "14px",
    textTransform: "unset",
    border: "1px solid white",
    "&:hover": {
      backgroundColor: "unset",
      border: "1px solid #C4C4C4",
      boxShadow: "none",
    },
  },
  payFull: {
    width: "45%",
    borderRadius: "32px",
    boxShadow: "none",
    py: "10px",
    fontWeight: "medium",
    fontSize: "14px",
    textTransform: "unset",
    "&:hover": {
      backgroundColor: "white",
      color: lightTheme.palette.secondary.main,
      border: "1px solid #C4C4C4",
      boxShadow: "none",
    },
    border: "1px solid #C4C4C4 !important",
    backgroundColor: "white",
    color: lightTheme.palette.secondary.main,
  },
};
export default CustomAmountModal;
