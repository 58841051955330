import { DiscountItem, MenuItem, Order } from "../models/API";
// Format date object to YYYYMMDDHHMMSS
export function formatDateToYYYYMMDDHHMMSS(date: Date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 because months are zero-indexed
  const day = String(date.getDate()).padStart(2, "0");
  const hour = String(date.getHours()).padStart(2, "0");
  const minute = String(date.getMinutes()).padStart(2, "0");
  const second = String(date.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
}

export function detectDevice(userAgent: string): "iOS" | "Android" | "Unknown" {
  if (
    userAgent.includes("iPhone") ||
    userAgent.includes("iPad") ||
    userAgent.includes("iPod")
  ) {
    return "iOS";
  } else if (userAgent.includes("Android")) {
    return "Android";
  } else {
    return "Unknown";
  }
}

export function getTableIDFromURL(url: string) {
  const urlArray = url.split("/");
  return urlArray[urlArray.length - 1];
}
export function calculateFinalPrice(
  menuItems: MenuItem[],
  discountItems: DiscountItem[],
  detailLink: string
) {
  // Convert the detailLink to a number for comparison
  const targetDetailLink = parseInt(detailLink);

  // Find the menu item with the given detailLink
  const menuItem = menuItems.find(
    (item) => parseInt(item.DetailLink) === targetDetailLink
  );
  if (!menuItem) {
    throw new Error(`Menu item with DetailLink ${detailLink} not found`);
  }

  // Find all applicable discounts
  const applicableDiscounts = discountItems.filter(
    (discount) => parseInt(discount.DetailLink) > targetDetailLink
  );

  // Calculate the total discount factor
  const totalDiscountFactor = applicableDiscounts.reduce((factor, discount) => {
    return factor * (1 - (discount.pcnt || 0));
  }, 1);

  // Calculate the final price
  if (!menuItem.totalAmount) {
    throw new Error(
      `Menu item with DetailLink ${detailLink} has no total amount`
    );
  }
  const finalPrice = menuItem.totalAmount * totalDiscountFactor;

  return finalPrice;
}
export function calculateFinalPriceV2(
  menuItems: MenuItem[],
  discountItems: DiscountItem[],
  detailLink: string,
  totalAmount: number
) {
  // Convert the detailLink to a number for comparison
  const targetDetailLink = parseInt(detailLink);

  // Find the menu item with the given detailLink
  const menuItem = menuItems.find(
    (item) => parseInt(item.DetailLink) === targetDetailLink
  );
  if (!menuItem) {
    throw new Error(`Menu item with DetailLink ${detailLink} not found`);
  }

  // Find all applicable discounts
  const applicableDiscounts = discountItems.filter(
    (discount) => parseInt(discount.DetailLink) > targetDetailLink
  );

  // Calculate the total discount factor
  const totalDiscountFactor = applicableDiscounts.reduce((factor, discount) => {
    return factor * (1 - (discount.pcnt || 0));
  }, 1);

  // Calculate the final price
  if (!totalAmount) {
    throw new Error(
      `Menu item with DetailLink ${detailLink} has no total amount`
    );
  }
  const finalPrice = totalAmount * totalDiscountFactor;

  return finalPrice;
}

export function truncateToTwoDecimals(number: number) {
  const flooredNumber = Math.floor(number * 100);
  const divided = flooredNumber / 100;
  return divided;
}
export function truncateToTwoDecimalsV2(number: number): number {
  const string = new String(number);
  const decimal = string.split(".")[1];
  const truncatedDecimal = decimal ? decimal.slice(0, 2) : "00";
  const truncatedNumber = string.split(".")[0] + "." + truncatedDecimal;
  return parseFloat(truncatedNumber);
}
export function getEquivalentSosocial(
  total: number,
  ratio: number | undefined
) {
  // console.log({ total, ratio });
  return total / (ratio || 1);
}
export function getEquivalentAED(usedCash: number, ratio: number | undefined) {
  // console.log({ usedCash, ratio });
  return usedCash * (ratio || 1);
}
export function isEqualToThirdDecimal(num1: number, num2: number): boolean {
  return Math.abs(num1 - num2) < 0.01;
}

export function detectPaymentType(order: Order): {
  itemByItem: boolean;
  customPayment: boolean;
} {
  const EPSILON = 0.01; // Tolerance for floating-point comparison

  if (!order.MenuItems || !order.MenuItems.length)
    return { customPayment: false, itemByItem: false };

  const totalItemPayments = order.MenuItems.reduce(
    (sum, item) => sum + item.paidQTY! * item.price!,
    0
  );

  // Determine if there is any item-by-item payment
  const itemByItem = order.MenuItems.some((item) => item.paidQTY! > 0);

  // Determine if there is any custom payment
  const customPayment =
    Math.abs(totalItemPayments - order.paidAmount!) > EPSILON;

  return { itemByItem, customPayment };
}
export function isDiscountItemLoyalty(
  discountItems: DiscountItem[] | undefined,
  discountObjectNum: string | undefined
): boolean {
  // Check if discountItems or discountObjectNum are not provided
  if (!discountItems || discountItems.length === 0) {
    return false;
  }
  if (!discountObjectNum) {
    return false;
  }

  // Iterate through the discountItems array
  for (let item of discountItems) {
    // If any item's ObjectNum does not match discountObjectNum, return true
    if (item.obj && item.obj === discountObjectNum) {
      return true;
    }
  }

  // If all ObjectNums match, return false
  return false;
}
export function isAllowedSplit(
  discountItems: DiscountItem[] | undefined
): boolean {
  const presetIds = ["1", "3", "4", "20", "21", "201"];
  // Check if discountItems or presetIds are not provided or empty
  if (!discountItems || discountItems.length === 0) {
    return true;
  }

  if (!presetIds || presetIds.length === 0) {
    return true;
  }

  // Iterate through the discountItems array
  for (let item of discountItems) {
    // Check if the item's obj matches any of the presetIds
    if (item.obj && presetIds.includes(item.obj)) {
      return false; // Return false if a match is found
    }
  }

  // Return true if no matches were found
  return true;
}

export function displayPrices(price: number) {
  const stringifiedPrice = price.toFixed(3); // Ensures 3 decimal places for consistent rounding
  let [beforeDecimal, afterDecimal] = stringifiedPrice.split(".");
  let result = beforeDecimal + ".";

  if (afterDecimal && afterDecimal.length > 2) {
    const thirdDigit = Number(afterDecimal[2]);

    if (thirdDigit >= 5) {
      // Handling rounding up of the second decimal place
      if (afterDecimal[1] === "9") {
        // Handle case where second decimal is "9", and rounding up is needed
        if (afterDecimal[0] === "9") {
          // If both decimals are "9", carry over to the whole number part
          beforeDecimal = (Number(beforeDecimal) + 1).toString(); // Increment the whole number part
          afterDecimal = "00"; // Reset decimal places to "00"
        } else {
          // Increment the first decimal place if it's not "9"
          afterDecimal = (Number(afterDecimal[0]) + 1).toString() + "0";
        }
      } else {
        // Regular rounding up when second decimal is not 9
        afterDecimal =
          afterDecimal[0] + (Number(afterDecimal[1]) + 1).toString();
      }
    } else {
      // No rounding needed, just take the first two decimals
      afterDecimal = afterDecimal.substring(0, 2);
    }
  }

  // Combine the whole number and decimal part for the final result
  result = beforeDecimal + "." + afterDecimal;
  return Number(result);
}
