import axios from "axios";
import { PaymentOperationIds } from "../constants/operationIds";
import { LamdaFnLinks } from "../constants/enums";

export async function createKey(concept: any, paymentTransaction: any) {
  try {
    const response = await axios.post(
      LamdaFnLinks.PaymentOperations +
        `?operation=${PaymentOperationIds.PAYMOB_KEY}`,
      {
        conceptID: concept ? concept.id : "",
        paymentTransaction,
      }
    );
    const data = response.data;
    return data;
  } catch (error) {}
}
