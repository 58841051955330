import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";

import Avatar from "@mui/material/Avatar";
import "./TrackPayment.css";
import {
  Box,
  Divider,
  LinearProgress,
  Typography,
  createTheme,
} from "@mui/material";
import { SplitData } from "../../constants/JsonObjects";
import lightThemeOptions from "../../theme/lightThemeOptions";
import { useTranslation } from "react-i18next";

const lightTheme = createTheme(lightThemeOptions);
function TrackPayment() {
  const [checked, setChecked] = React.useState([1]);
  const { t, i18n } = useTranslation();

  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };
  const statsRow = (color: any, title: any, data: any, percentage: any) => {
    return (
      <Box className="StatsRow">
        <Box className="flexDefaults">
          <Box
            style={{
              backgroundColor: color,
              borderRadius: 50,
              width: 10,
              height: 10,
              marginRight: i18n.language === "Arabic" ? 0 : 10,
              marginLeft: i18n.language === "Arabic" ? 10 : 0,
            }}
          ></Box>
          <Typography style={{ fontSize: 16, fontWeight: 500 }}>
            {title}
          </Typography>
        </Box>
        <Box className="flexDefaults">
          <Typography style={{ fontSize: 16, fontWeight: 700 }}>
            {data}
          </Typography>
          <Box className="Stats_perentageBox">
            <Typography
              style={{
                color: lightTheme.palette.primary.dark,
                fontSize: 14,
                fontWeight: 300,
              }}
            >
              {Math.round(percentage)}%
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  };
  const CalcPaid = (Data: any) => {
    let sum = 0;
    Data.forEach((person: any) => {
      sum += person.value;
    });
    return sum;
  };

  return (
    <Box
      sx={{
        width: "90%",
        border: "0.5px grey solid",
        borderRadius: "15px",
        bgcolor: "background.paper",
      }}
    >
      <List dense>
        {SplitData.map((value) => {
          const labelId = `checkbox-list-secondary-label-${value.id}`;
          return (
            <ListItem
              key={value.id}
              secondaryAction={
                i18n.language === "Arabic" ? (
                  <></>
                ) : (
                  <ListItemText
                    id={labelId}
                    primary={value.value + value.currency}
                  />
                )
              }
              disablePadding
            >
              <ListItemButton>
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: "#BCAF98" }}>
                    {value.name === "You" ? null : value.name.slice(0, 2)}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText id={labelId} primary={value.name} />
                <LinearProgress
                  value={(value.value / value.total) * 100}
                  variant="determinate"
                  // max={value.total}
                  sx={{
                    marginRight: "3rem",
                    transform: "scaleX(1.3)",
                    flexGrow: "1",
                    color: lightTheme.palette.primary.dark,
                    backgroundColor: lightTheme.palette.primary.light,
                  }}
                />
                {i18n.language === "Arabic" && (
                  <ListItemText
                    id={labelId}
                    primary={value.value + t(value.currency)}
                  />
                )}
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
      <Divider />
      <Divider style={{ marginTop: "4px" }} />

      <Box className="StatsBox">
        {statsRow(
          lightTheme.palette.primary.dark,
          t("Paid"),
          CalcPaid(SplitData) + t("EGP"),
          (100 * CalcPaid(SplitData)) / SplitData[0].total
        )}
        {statsRow(
          lightTheme.palette.primary.light,
          t("Remaining"),
          SplitData[0].total - CalcPaid(SplitData) + t("EGP"),
          (100 * (SplitData[0].total - CalcPaid(SplitData))) /
            SplitData[0].total
        )}
      </Box>
    </Box>
  );
}

export default TrackPayment;
