export enum TableManagementOperationIds {
  GET_TABLE_BY_CODE = "get-table-by-code",
  UPDATE_ORDER_RATING = "update_order",
  GET_MAIN_CATEGORIES = "get_main_categories",
  GET_PENDING_PAYMENTS = "get_pending_payments",
}

export enum SimphonyOperationIds {
  GET_TABLES = "getTables",
  SYNC_TABLES = "syncTables",
  UPDATE_ORDER = "updateOrder",
  GET_ORDER_BY_TABLE = "getOrderByTable",
  PAY_ORDER = "payOrder",
  SEND_EMAIL = "sendEmail",
}

export enum PaymentOperationIds {
  APPLE_SESSION = "appleSession",
  CCAvenue_TRANSACTION = "CCAvenueTransaction",
  CCAvenue_APPLE_TRANSACTION = "CCAvenueAppleTransaction",
  CCAvenue_PAYMENT_STATUS = "CCAvenuePaymentStatus",
  PAYMOB_KEY = "paymobCreateKey",
}

export enum LoyaltyOperationIds {
  generateToken = 1,
  sendOtp = 2,
  verifyOtp = 3,
  getUserDetails = 4,
  redeemAmount = 5,
  earnCashBack = 6,
}
