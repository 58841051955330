import { IoIosCloseCircleOutline } from "react-icons/io";
import {
  Backdrop,
  Box,
  Button,
  Grid,
  Slide,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  createTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import lightThemeOptions from "../../theme/lightThemeOptions";
import { useEffect, useState } from "react";
import { TranslationsEnum } from "../../constants/TranslationsEnum";
import { useSelector } from "react-redux";
import { StoreState } from "../../store/ducks";
import { calcWithServiceFees } from "../../pages/Invoice/InvoiceTable";
import { FaCircleCheck } from "react-icons/fa6";
import { IoMdCheckmarkCircle } from "react-icons/io";
import { MenuItem, Order } from "../../models/API";
import { toast } from "react-toastify";
import { calculateFinalPriceV2, displayPrices } from "../../helpers/utils";
const lightTheme = createTheme(lightThemeOptions);

type Props = {
  open: boolean;
  onClose: () => void;
  handleSubmit: (value: number, selectedMenuItems: MenuItem[]) => void;
  handleRemove: () => void;
};
function PayForItemsModal({
  open,
  onClose,
  handleSubmit,
  handleRemove,
}: Props) {
  const { t, i18n } = useTranslation();
  const handleClose = () => {
    onClose();
  };
  const { order, selectedMenuItems } = useSelector(
    (state: StoreState) => state.order
  );
  const { serviceFee } = useSelector(
    (state: StoreState) => state.app.conceptOptions
  )!;
  const initiallySelectedItems = selectedMenuItems?.map(
    (item) => item.DetailLink!
  );
  const [selectedItems, setSelectedItems] = useState<string[]>(
    initiallySelectedItems || []
  );
  const selectItem = (itemDetailLink: string) => {
    if (!selectedItems.includes(itemDetailLink)) {
      setSelectedItems((prev) => [...prev, itemDetailLink]);
    } else if (selectedItems.includes(itemDetailLink)) {
      const newArray = selectedItems.filter((item) => item !== itemDetailLink);
      setSelectedItems(newArray);
    }
  };
  const handleRemoveSplit = () => {
    handleRemove();
    onClose();
  };

  const getItemsWithPrice = () => {
    const selecteddItems = getItemsFromIDs(selectedItems, order?.MenuItems!);

    const discountItems = order?.DiscountItems || [];

    const selectedItemsWithFinalPrices = selecteddItems.map((item) => ({
      ...item,
      finalPrice: calculateFinalPriceV2(
        order?.MenuItems!,
        discountItems,
        item.DetailLink,
        item.totalAmount || 0
      ),
    }));

    const totalSelectedAmount = selectedItemsWithFinalPrices.reduce(
      (acc, item) => acc + item.finalPrice * 100,
      0
    );

    return {
      items: selectedItemsWithFinalPrices,
      // price: parseFloat(totalSelectedAmount.toFixed(3)),
      price: totalSelectedAmount / 100,
    };
  };

  useEffect(() => {
    setSelectedItems(initiallySelectedItems || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMenuItems, open]);

  function spreadMenuItems(order: any): any[] {
    if (!order || !order.MenuItems) return [];
    return order.MenuItems.flatMap((item: any) => {
      return Array.from({ length: item.SalesCount }, (_, index) => {
        return {
          ...item,
          SalesCount: 1,
          mTaxCollectedTotal: item.mTaxCollectedTotal / item.SalesCount,
          totalAmount: item.totalAmount / item.SalesCount,
          Total: item.Total / item.SalesCount,
          paidQTY: index < item.paidQTY ? 1 : 0, // Set paidQTY based on index
          id: `${index}-${item.DetailLink}`,
        };
      });
    });
  }

  return (
    <>
      <Backdrop
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 0,
          alignItems: "flex-start",
          overflowY: "auto",
        }}
        onClick={(e) => handleClose()}
        open={open}
      >
        <Slide in={open} direction="up">
          <Box sx={classes.containerBox} onClick={(e) => e.stopPropagation()}>
            <Grid
              container
              sx={{
                ...classes.containerGrid,
                minHeight: window.innerHeight - 70,
              }}
            >
              <Grid
                item
                sx={{
                  gap: "10px",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Typography
                    fontSize={15}
                    fontWeight="medium"
                    color={lightTheme.palette.secondary.main}
                  >
                    Pay for Your Items
                  </Typography>
                  <IoIosCloseCircleOutline
                    color="red"
                    onClick={handleClose}
                    size={"24px"}
                  />{" "}
                </Box>
              </Grid>

              <Grid
                item
                sx={{
                  gap: "10px",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  mb: "auto",
                  pt: "10px",
                }}
              >
                <TableContainer>
                  <Table size="medium" aria-label="a dense table">
                    <TableBody>
                      {spreadMenuItems(order).map((item: any) => {
                        return (
                          <>
                            <TableRow
                              key={item.id}
                              onClick={() => {
                                item.paidQTY !== item.SalesCount &&
                                  selectItem(item.id);
                              }}
                              sx={{
                                cursor: "default",
                                borderColor: lightTheme.palette.primary.main,
                                borderStyle: "solid",
                                borderWidth: "1px 0px 1px 0px",
                              }}
                            >
                              <TableCell
                                align="center"
                                sx={{
                                  px: "0px",
                                }}
                                colSpan={2}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      backgroundColor:
                                        lightTheme.palette.primary.light,
                                      width: "20px",
                                      height: "20px",
                                      borderRadius: "50%",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Typography
                                      fontSize={12}
                                      color={lightTheme.palette.secondary.main}
                                      fontWeight={"medium"}
                                    >
                                      {item.SalesCount}
                                    </Typography>
                                  </Box>
                                  <Typography
                                    fontSize={12}
                                    color={lightTheme.palette.secondary.main}
                                    fontWeight={"medium"}
                                    textAlign={"left"}
                                  >
                                    {item.Name} @{" "}
                                    <Typography
                                      component={"span"}
                                      fontSize={12}
                                      fontWeight={"medium"}
                                      color={
                                        !(item.DetailType === "DtlMiType")
                                          ? "#789E75"
                                          : lightTheme.palette.secondary.main
                                      }
                                    >
                                      EGP&nbsp;
                                    </Typography>
                                    {item.price?.toFixed(2)}
                                  </Typography>
                                </Box>
                              </TableCell>
                              <TableCell
                                align={
                                  i18n.language === "Arabic" ? "left" : "right"
                                }
                                style={{
                                  color: "#000",
                                  padding: "0px",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "5px",
                                    justifyContent: "end",
                                  }}
                                >
                                  <Typography
                                    fontSize={12}
                                    fontWeight={"medium"}
                                    color={lightTheme.palette.secondary.main}
                                  >
                                    <Typography
                                      component={"span"}
                                      fontSize={12}
                                      fontWeight={"medium"}
                                      color={
                                        !(item.DetailType === "DtlMiType")
                                          ? "#789E75"
                                          : lightTheme.palette.secondary.main
                                      }
                                    >
                                      EGP&nbsp;
                                    </Typography>
                                    {`${item.totalAmount?.toFixed(2)}`}
                                  </Typography>
                                  <IoMdCheckmarkCircle
                                    fontSize={"23px"}
                                    // onClick={() => {
                                    //   item.paidQTY !== item.SalesCount &&
                                    //     selectItem(item.DetailLink!);
                                    // }}
                                    color={
                                      item.paidQTY === item.SalesCount
                                        ? "green"
                                        : selectedItems.includes(item.id)
                                        ? "black"
                                        : lightTheme.palette.primary.main
                                    }
                                  />
                                </Box>
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      })}
                      <TableRow sx={{ width: "100%" }}>
                        <TableCell sx={{ px: "0px" }} colSpan={2}>
                          <Typography
                            sx={{
                              textAlign:
                                i18n.language === "Arabic" ? "right" : "left",
                              fontWeight: "medium",
                              color: lightTheme.palette.secondary.main,
                              fontSize: "12px",
                              marginLeft: "30px",
                            }}
                          >
                            {t(TranslationsEnum.serviceFee)}
                          </Typography>
                        </TableCell>
                        <TableCell
                          colSpan={2}
                          sx={{
                            height: "10px",
                          }}
                        >
                          <Typography
                            sx={{
                              textAlign: "right",
                              fontWeight: "medium",
                              color: lightTheme.palette.secondary.main,
                              fontSize: "12px",
                            }}
                          >
                            <Typography
                              component={"span"}
                              fontSize={12}
                              fontWeight={"medium"}
                              color={lightTheme.palette.secondary.main}
                            >
                              EGP&nbsp;
                            </Typography>
                            {displayPrices(
                              calcWithServiceFees({
                                total: getItemsWithPrice().price,
                                serviceFee,
                              }).serviceFee
                            ).toFixed(2)}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>

              <Grid
                item
                sx={{
                  gap: "10px",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  mt: "auto",
                  borderTop: "1px solid " + lightTheme.palette.primary.main,
                  px: "5px",
                  pt: "10px",
                }}
              >
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    fontSize={14}
                    fontWeight={"medium"}
                    color={lightTheme.palette.secondary.main}
                  >
                    Left to Pay
                  </Typography>
                  <Typography
                    fontSize={14}
                    fontWeight={"medium"}
                    color={lightTheme.palette.secondary.main}
                  >
                    {" "}
                    <Typography
                      component={"span"}
                      fontSize={14}
                      fontWeight={"medium"}
                    >
                      EGP&nbsp;
                    </Typography>
                    {displayPrices(
                      displayPrices(order?.Due!) +
                        displayPrices(
                          calcWithServiceFees({
                            serviceFee: serviceFee,
                            total: order?.Due!,
                          }).serviceFee
                        )
                    ).toFixed(2)}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    fontSize={14}
                    fontWeight={"medium"}
                    color={lightTheme.palette.secondary.main}
                  >
                    Your Share
                  </Typography>
                  <Typography
                    fontSize={14}
                    fontWeight={"medium"}
                    color={lightTheme.palette.secondary.main}
                  >
                    <Typography
                      component={"span"}
                      fontSize={14}
                      fontWeight={"medium"}
                    >
                      EGP&nbsp;
                    </Typography>
                    {displayPrices(
                      displayPrices(getItemsWithPrice().price) +
                        calcWithServiceFees({
                          total: getItemsWithPrice().price,
                          serviceFee,
                        }).serviceFee
                    ).toFixed(2)}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Button
                    variant="contained"
                    sx={classes.splitButton}
                    onClick={handleRemoveSplit}
                  >
                    Remove Split
                  </Button>
                  <Button
                    variant="contained"
                    sx={classes.payFull}
                    disabled={selectedItems.length === 0}
                    onClick={() => {
                      const { items, price } = getItemsWithPrice();
                      const priceWithServiceFee =
                        price +
                        calcWithServiceFees({ serviceFee, total: price })
                          .serviceFee;
                      const dueWithServiceFee =
                        order?.Due! +
                        calcWithServiceFees({ serviceFee, total: order?.Due! })
                          .serviceFee;
                      if (
                        Math.abs(priceWithServiceFee - dueWithServiceFee) >=
                        0.01
                      ) {
                        toast.info("You can't pay more than the due amount");
                        return;
                      }

                      handleSubmit(price, items!);
                      setSelectedItems([]);
                    }}
                  >
                    Confirm
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Slide>
      </Backdrop>
    </>
  );
}
const classes = {
  containerBox: {
    boxSizing: "border-box",
    position: "relative",
    width: "100%",
    minHeight: "100%",
    background: "#FFFFFF",
    padding: {
      xs: "35px",
      sm: "1rem",
      md: "1.5rem",
    },
    maxWidth: "500px",
  } as SxProps,
  containerGrid: {
    minHeight: "100%",
    gap: "1rem",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "start",
    flexDirection: "column",
  },
  incrementButton: {
    padding: "0px",
    width: "21px",
    height: "21px",
    backgroundColor: "#d9d9d9",
    color: "#707070",
    borderRadius: "50%",
    textAlign: "center",
    fontSize: "24px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  splitButton: {
    width: "45%",
    backgroundColor: "#FAFAFA",
    borderRadius: "32px",
    boxShadow: "none",
    py: "10px",
    color: "red",
    fontWeight: "medium",
    fontSize: "14px",
    textTransform: "unset",
    border: "1px solid white",
    "&:hover": {
      backgroundColor: "unset",
      border: "1px solid #C4C4C4",
      boxShadow: "none",
    },
  },
  payFull: {
    width: "45%",
    backgroundColor: "#FAFAFA",
    borderRadius: "32px",
    boxShadow: "none",
    py: "10px",
    color: lightTheme.palette.secondary.main,
    fontWeight: "medium",
    fontSize: "14px",
    border: "1px solid white",
    textTransform: "unset",
    "&:hover": {
      backgroundColor: "unset",
      border: "1px solid #C4C4C4",
      boxShadow: "none",
    },
  },
};
export default PayForItemsModal;

function getItemsFromDetailLinks(DetailLinks: string[], MenuItems: MenuItem[]) {
  const items: MenuItem[] = [];

  DetailLinks.forEach((DetailLink) => {
    const menuItem = MenuItems.find((item) => item.DetailLink === DetailLink);
    if (menuItem) {
      items.push(menuItem);
    }
  });
  return items;
}

function getItemsFromIDs(IDs: string[], MenuItems: MenuItem[]): MenuItem[] {
  // Create a map to store the aggregated SalesCount for each DetailLink
  const salesCountMap: Record<string, number> = {};
  if (!MenuItems) return [];
  // Iterate over the IDs to populate the salesCountMap
  IDs.forEach((id) => {
    const [, detailLink] = id.split("-");

    if (detailLink) {
      // Initialize or update the SalesCount for the detailLink
      if (!salesCountMap[detailLink]) {
        salesCountMap[detailLink] = 0;
      }
      salesCountMap[detailLink] += 1; // Increment SalesCount for each occurrence
    }
  });

  // Map the aggregated SalesCount to the menu items
  const items: MenuItem[] = MenuItems.map((item) => {
    const aggregatedSalesCount = salesCountMap[item.DetailLink];

    // Check if the aggregated sales count is equal to the original sales count
    if (aggregatedSalesCount === item.SalesCount) {
      return item; // No modification needed
    }

    // Modify item if aggregated sales count differs
    if (aggregatedSalesCount) {
      return {
        ...item,
        SalesCount: aggregatedSalesCount,
        mTaxCollectedTotal:
          item.mTaxCollectedTotal && item.SalesCount
            ? (item.mTaxCollectedTotal / item.SalesCount) * aggregatedSalesCount
            : 0,
        totalAmount:
          item.totalAmount && item.SalesCount
            ? (item.totalAmount / item.SalesCount) * aggregatedSalesCount
            : 0,
        Total:
          item.Total && item.SalesCount
            ? (item.Total / item.SalesCount) * aggregatedSalesCount
            : 0,
      };
    }

    return null; // Filter out null values
  }).filter((item) => item !== null) as MenuItem[]; // Filter out any null values

  return items;
}
