import {
  Backdrop,
  Box,
  Button,
  Grid,
  Typography,
  createTheme,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { IoMdClose } from "react-icons/io";
import { TranslationsEnum } from "../../constants/TranslationsEnum";
import { useSelector } from "react-redux";
import { StoreState } from "../../store/ducks";
import lightThemeOptions from "../../theme/lightThemeOptions";
const lightTheme = createTheme(lightThemeOptions);

type Props = {
  open: boolean;
  onClose: () => void;
};
function WalletModal({ open, onClose }: Props) {
  const { t, i18n } = useTranslation();
  const [otp, setOtp] = useState("");
  const user = useSelector((state: StoreState) => state.app.user);
  const total = useSelector((state: StoreState) => state.order.total);
  const handleClose = () => {
    onClose();
  };

  return (
    <>
      <Backdrop
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 0,
          alignItems: "flex-start",
        }}
        open={open}
      >
        <Box sx={classes.containerBox}>
          <Box
            sx={{
              position: "absolute",
              top: "10px",
              right: "10px",
              cursor: "pointer",
            }}
            onClick={handleClose}
          >
            <IoMdClose size={24} color="#603813" />
          </Box>
          <Grid container sx={classes.containerGrid}>
            <Grid
              item
              sx={{ gap: "10px", display: "flex", flexDirection: "column" }}
              xs={12}
            >
              <Typography sx={classes.walletTitle}>
                {t(TranslationsEnum.welcomeWallet, {
                  name: user && user.first_name,
                })}
              </Typography>
              <Typography sx={classes.walletTitle}>
                {t(TranslationsEnum.walletBalanceText, {
                  balance: user && user.wallet.amount,
                })}
              </Typography>
              {user &&
                (Number(user.wallet.amount) < total ? (
                  <Typography sx={classes.walletText}>
                    By completing this order you will receive{" "}
                    <Typography
                      fontSize={"inherit"}
                      component={"span"}
                      fontWeight={700}
                    >
                      {Math.floor(total - Number(user?.wallet.amount))}
                    </Typography>{" "}
                    SoSocial Cash in your loyalty balance.
                  </Typography>
                ) : (
                  <Typography sx={classes.walletText}>
                    You Can use your SoSocial Cash to pay for this order.
                  </Typography>
                ))}
              <Button
                disableRipple={true}
                variant={"contained"}
                onClick={handleClose}
                sx={classes.button}
              >
                Pay With Wallet
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Backdrop>
    </>
  );
}
const classes = {
  containerBox: {
    boxSizing: "border-box",
    position: "relative",
    top: "50%",
    width: "80%",
    transform: "translateY(-50%)",
    background: "white",
    padding: {
      xs: "1.5rem",
      sm: "1rem",
      md: "1.5rem",
    },
    borderRadius: "10px",
    maxWidth: "500px",
  },
  containerGrid: {
    height: "100%",
    gap: "1rem",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
    justifyItems: "center",
  },
  walletTitle: {
    fontWeight: "600",
  },
  walletText: {
    fontWeight: "300",
    fontSize: "14px",
  },
  button: {
    backgroundColor: lightTheme.palette.primary.main,
    borderRadius: "10px",
  },
};
export default WalletModal;
