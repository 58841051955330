import { Box, BoxProps, SxProps } from "@mui/material";
import { FC } from "react";
import { useSelector } from "react-redux";
import { StoreState } from "../../store/ducks";
import S3URL from "../../constants/S3URL";

// const logos = {
//   primary: require(`../../assets/tiki/logo-primary.png`),
//   light: require(`../../assets/tiki/logo-light.png`),
//   dark: require(`../../assets/tiki/logo-dark.png`),
// };
interface LogoProps {
  color: "primary" | "light" | "dark";
  sx?: SxProps;
  boxProps?: BoxProps;
  goDefault?: boolean;
  onLoad?: () => void;
}

const Logo: FC<LogoProps> = ({ sx, color, boxProps, goDefault, onLoad }) => {
  const conceptOptions = useSelector(
    (state: StoreState) => state.app.conceptOptions
  );
  let logo = conceptOptions?.logo
    ? `${S3URL}${conceptOptions.logo}`
    : require(`../../assets/tiki/logo-${color}.webp`);

  return (
    <Box
      {...boxProps}
      component="img"
      alt="logo"
      src={logo}
      sx={sx}
      onLoad={onLoad}
    />
  );
};

export default Logo;
