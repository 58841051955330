import { Grid, CircularProgress } from "@mui/material";

const MainLoader = () => {
  return (
    <Grid
      item
      container
      sm={12}
      justifyContent="center"
      alignItems="center"
      flexDirection={"column"}
      sx={{
        height: "100vh",
        background: "white",
        position: "absolute",
        zIndex: 9999,
        gap: "20px",
      }}
    >
      <CircularProgress size={64}/>
    </Grid>
  );
};

export default MainLoader;
